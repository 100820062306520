/* eslint-disable max-len */
export const fakeData = {
  jobListingTitle: {
    title: 'Rejoignez la team BEMAC',
    subtitle: 'Jobs',
    picture: {
      // eslint-disable-next-line max-len
      src:
        // eslint-disable-next-line max-len
        'https://cms.pairidaiza.eu/thumbor/GHUjIGwOWsu_ayDEd57DccjDn5A=/fit-in/1024x/filters:quality(92)/--/uploads/2024/06/MORSE-SOLENE-4x3-450dpi-BG-V02-optimized-1.jpg',
    },
    pictureHover: {
      // eslint-disable-next-line max-len
      src:
        // eslint-disable-next-line max-len
        'https://specials-images.forbesimg.com/imageserve//62bbf439b7db6b36c4d17a99/960x0.jpg',
    },
    description:
      // eslint-disable-next-line max-len
      'Leader belge des systèmes de détection incendie. Concepteur, fabricant et installateur. Pour nous, chaque vie compte !',
  },
  valuesGrid: {
    title: 'Nos valeurs <strong>P.R.I.S.E.</strong>',
    items: [
      {
        title: 'Professionnalisme',
        picture: {
          // eslint-disable-next-line max-len
          src:
            // eslint-disable-next-line max-len
            'https://cms.pairidaiza.eu/thumbor/GHUjIGwOWsu_ayDEd57DccjDn5A=/fit-in/1024x/filters:quality(92)/--/uploads/2024/06/MORSE-SOLENE-4x3-450dpi-BG-V02-optimized-1.jpg',
        },
        htmltext:
          // eslint-disable-next-line max-len, quotes
          "<p>Chez Bemac, l'autonomie dans l'exercice de nos fonctions permet plus d'initiative, de créativité et d'innovation tout en nous rendant davantage responsables de nos actions et décisions.</p><p>Ce mode de fonctionnement s'appuie sur la confiance, la solidarité et la loyauté de tous, de même que sur l'engagement du management à soutenir les employés.</p>",
      },
      {
        title: 'Responsabilité',
        picture: {
          // eslint-disable-next-line max-len
          src:
            // eslint-disable-next-line max-len
            'https://specials-images.forbesimg.com/imageserve//62bbf439b7db6b36c4d17a99/960x0.jpg',
        },
        htmltext:
          // eslint-disable-next-line max-len, quotes
          "Chez Bemac, l'autonomie dans l'exercice de nos fonctions permet plus d'initiative, de créativité et d'innovation tout en nous rendant davantage responsables de nos actions et décisions. Ce mode de fonctionnement s'appuie sur la confiance, la solidarité et la loyauté de tous, de même que sur l'engagement du management à soutenir les employés.",
      },
      {
        title: 'Innovation',
        picture: {
          // eslint-disable-next-line max-len
          src:
            // eslint-disable-next-line max-len
            'https://cms.pairidaiza.eu/thumbor/GHUjIGwOWsu_ayDEd57DccjDn5A=/fit-in/1024x/filters:quality(92)/--/uploads/2024/06/MORSE-SOLENE-4x3-450dpi-BG-V02-optimized-1.jpg',
        },
        htmltext:
          // eslint-disable-next-line max-len, quotes
          "Chez Bemac, l'autonomie dans l'exercice de nos fonctions permet plus d'initiative, de créativité et d'innovation tout en nous rendant davantage responsables de nos actions et décisions. Ce mode de fonctionnement s'appuie sur la confiance, la solidarité et la loyauté de tous, de même que sur l'engagement du management à soutenir les employés.",
      },
      {
        title: 'Satisfaction client',
        picture: {
          // eslint-disable-next-line max-len
          src:
            // eslint-disable-next-line max-len
            'https://specials-images.forbesimg.com/imageserve//62bbf439b7db6b36c4d17a99/960x0.jpg',
        },
        htmltext:
          // eslint-disable-next-line max-len, quotes
          "Chez Bemac, l'autonomie dans l'exercice de nos fonctions permet plus d'initiative, de créativité et d'innovation tout en nous rendant davantage responsables de nos actions et décisions. Ce mode de fonctionnement s'appuie sur la confiance, la solidarité et la loyauté de tous, de même que sur l'engagement du management à soutenir les employés.",
      },
      {
        // eslint-disable-next-line quotes
        title: "Esprit d'équipe",
        picture: {
          // eslint-disable-next-line max-len
          src:
            // eslint-disable-next-line max-len
            'https://cms.pairidaiza.eu/thumbor/GHUjIGwOWsu_ayDEd57DccjDn5A=/fit-in/1024x/filters:quality(92)/--/uploads/2024/06/MORSE-SOLENE-4x3-450dpi-BG-V02-optimized-1.jpg',
        },
        htmltext:
          // eslint-disable-next-line max-len, quotes
          "Chez Bemac, l'autonomie dans l'exercice de nos fonctions permet plus d'initiative, de créativité et d'innovation tout en nous rendant davantage responsables de nos actions et décisions. Ce mode de fonctionnement s'appuie sur la confiance, la solidarité et la loyauté de tous, de même que sur l'engagement du management à soutenir les employés.",
      },
    ],
  },
  jobsSlider: {
    title: 'Postes vacants',
    items: [
      {
        department: 'Technics',
        title: '1 Technicien électronicien',
        details: 'CDI - Liège',
        picture: {
          // eslint-disable-next-line max-len
          src:
            // eslint-disable-next-line max-len
            'https://cms.pairidaiza.eu/thumbor/GHUjIGwOWsu_ayDEd57DccjDn5A=/fit-in/1024x/filters:quality(92)/--/uploads/2024/06/MORSE-SOLENE-4x3-450dpi-BG-V02-optimized-1.jpg',
        },
        link: {
          url: '/fr/references/liege-expo/',
          target: '',
          title: 'Click me',
        },
      },
      {
        department: 'Technics',
        title: '2 Technicien électronicien',
        details: 'CDI - Liège',
        picture: {
          // eslint-disable-next-line max-len
          src:
            // eslint-disable-next-line max-len
            'https://cms.pairidaiza.eu/thumbor/GHUjIGwOWsu_ayDEd57DccjDn5A=/fit-in/1024x/filters:quality(92)/--/uploads/2024/06/MORSE-SOLENE-4x3-450dpi-BG-V02-optimized-1.jpg',
        },
        link: {
          url: '/fr/references/liege-expo/',
          target: '',
          title: 'Click me',
        },
      },
      {
        department: 'Technics',
        title: '3 Technicien électronicien',
        details: 'CDI - Liège',
        picture: {
          // eslint-disable-next-line max-len
          src:
            // eslint-disable-next-line max-len
            'https://cms.pairidaiza.eu/thumbor/GHUjIGwOWsu_ayDEd57DccjDn5A=/fit-in/1024x/filters:quality(92)/--/uploads/2024/06/MORSE-SOLENE-4x3-450dpi-BG-V02-optimized-1.jpg',
        },
        link: {
          url: '/fr/references/liege-expo/',
          target: '',
          title: 'Click me',
        },
      },
      {
        department: 'Technics',
        title: '4 Technicien électronicien',
        details: 'CDI - Liège',
        picture: {
          // eslint-disable-next-line max-len
          src:
            // eslint-disable-next-line max-len
            'https://cms.pairidaiza.eu/thumbor/GHUjIGwOWsu_ayDEd57DccjDn5A=/fit-in/1024x/filters:quality(92)/--/uploads/2024/06/MORSE-SOLENE-4x3-450dpi-BG-V02-optimized-1.jpg',
        },
        link: {
          url: '/fr/references/liege-expo/',
          target: '',
          title: 'Click me',
        },
      },
    ],
    htmltext:
      // eslint-disable-next-line max-len
      '<p><b>Nous n’avons pas de postes vacants</b> actuellement. BEMAC recrute néanmoins constament differents postes tel que des <b>electroniciens, commerciaux, agents administratif</b>, lorem ispum itset.</p>',
  },
  duoCtas: {
    title: 'Nous sommes toujours à la recherche de nouveau talents',
    items: [
      {
        title: 'Liège Expo',
        url: '/fr/carriere/technicienne-itinerante-h-f-x/',
      },
      {
        title: 'Team Leader (H/F/X)',
        url: '/fr/carriere/team-leader-h-f-x/',
      },
    ],
  },
  testimonialsSlider: {
    title: 'Temoignages',
    items: [
      {
        title: 'Une entreprise novatrice!',
        htmltext:
          // eslint-disable-next-line max-len
          'Nous voulons créer un portail unique contentant tout ce dont nos salariés ont besoin pour vivre une vie plus saine et plus heureuse. 200 caracteres max. / environ 30 mots',
        name: 'Aline Demaret',
        job: 'Project Manager',
        picture: {
          // eslint-disable-next-line max-len
          src:
            // eslint-disable-next-line max-len
            'https://cms.pairidaiza.eu/thumbor/GHUjIGwOWsu_ayDEd57DccjDn5A=/fit-in/1024x/filters:quality(92)/--/uploads/2024/06/MORSE-SOLENE-4x3-450dpi-BG-V02-optimized-1.jpg',
        },
      },
    ],
  },
  quote: {
    picture: {
      src:
        // eslint-disable-next-line max-len
        'https://cms.pairidaiza.eu/thumbor/GHUjIGwOWsu_ayDEd57DccjDn5A=/fit-in/1024x/filters:quality(92)/--/uploads/2024/06/MORSE-SOLENE-4x3-450dpi-BG-V02-optimized-1.jpg',
    },
    htmltext:
      // eslint-disable-next-line max-len, quotes
      "<p class='lead'>Chez BEMAC, notre succès ne se mesure pas uniquement en chiffres, mais dans la manière dont nous plaçons l'humain au cœur de chaque projet.</p><p>Nous croyons fermement que chaque idée, chaque collaboration et chaque réussite naît d'une culture d'écoute, de respect et de soutien mutuel. C'est ensemble, avec nos talents et nos valeurs, que nous continuons à réinventer notre industrie et à bâtir un avenir où chacun trouve sa place.</p>",
    name: 'John Doe',
    job: 'CEO',
  },
  life: {
    title: 'Foo',
    pictures: [
      {
        src: 'https://placehold.co/2414x3035/EEE/31343C',
        alt: '',
        caption: '',
        description: '',
        width: 2414,
        height: 3035,
      },
      {
        src: 'https://placehold.co/2016x1344/EEE/31343C',
        alt: '',
        caption: '',
        description: '',
        width: 2016,
        height: 1344,
      },
      {
        src: 'https://placehold.co/2016x1344/EEE/31343C',
        alt: '',
        caption: '',
        description: '',
        width: 2016,
        height: 1344,
      },
    ],
  },
  jobCta: {
    title: 'pourquoi bemac',
    htmltext: '<p>coucou bemac</p>\n',
    link: {
      url: '/fr/references/liege-expo/',
      target: '',
      label: 'Click me',
    },
    picture: {
      // eslint-disable-next-line max-len
      src:
        // eslint-disable-next-line max-len
        'https://cms.pairidaiza.eu/thumbor/GHUjIGwOWsu_ayDEd57DccjDn5A=/fit-in/1024x/filters:quality(92)/--/uploads/2024/06/MORSE-SOLENE-4x3-450dpi-BG-V02-optimized-1.jpg',
    },
  },
  heroJob: {
    headline: 'Jobs',
    title: 'Technicien électronicien',
    details: 'CDD - Alleur',
    picture: {
      // eslint-disable-next-line max-len
      src:
        // eslint-disable-next-line max-len
        'https://cms.pairidaiza.eu/thumbor/GHUjIGwOWsu_ayDEd57DccjDn5A=/fit-in/1024x/filters:quality(92)/--/uploads/2024/06/MORSE-SOLENE-4x3-450dpi-BG-V02-optimized-1.jpg',
    },
  },
}
