<template>
  <div v-enter-viewport @enterViewport="animateIn">
    <div class="card-job">
      <div class="card-job__picture-container">
        <ZoomOutImg
          :autoplay="false"
          :className="'card-job__picture'"
          :sets="[350, 640]"
          :picture="content.picture"
        />
      </div>
      <div class="card-job__content">
        <div class="card-job__category" v-if="content.department">
          <LineSplitter
            class="card-job__category alt-heading"
            :content="content.department"
            ref="categoryRef"
          />
        </div>
        <LineSplitter
          class="card-job__title"
          :content="content.title"
          ref="titleRef"
        />
        <LineSplitter
          class="card-job__details"
          v-if="content.details"
          :content="content.details"
          ref="detailsRef"
        />

        <router-link class="card-job__link" :to="content.link.url">
          <span>{{ content.link.title }}</span>
          <img src="@/assets/img/arrow-rounded.svg" :alt="content.link.label" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import ZoomOutImg from '@/components/animation/ZoomOutImg.vue'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

export default defineComponent({
  name: 'card-job',
  components: {
    ZoomOutImg,
    LineSplitter,
  },
  props: {
    content: {
      type: Object,
    },
  },
  setup() {
    const categoryRef = ref([])
    const titleRef = ref()
    const detailsRef = ref()

    const animateIn = () => {
      if (motionOK()) {
        const stagger = 0.1
        const duration = 1
        const offset = duration - stagger

        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (categoryRef.value) {
          TL.add(categoryRef.value.getTween(), 'start')
        }

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), `-=${offset}`)
        }

        if (detailsRef.value) {
          TL.add(detailsRef.value.getTween(), `-=${offset}`)
        }
      }
    }

    return {
      animateIn,
      categoryRef,
      titleRef,
      detailsRef,
    }
  },
})
</script>

<style lang="scss" scoped>
.card-job {
  text-align: left;
  background-color: $c-pampas;
}

.card-job__picture-container {
  position: relative;
  overflow: hidden;
  aspect-ratio: 1;

  ::v-deep {
    .clip {
      aspect-ratio: 1;
    }

    .card-job__picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.card-job__content {
  @include liquid(padding, 20px, 30px);
}

.card-job__category {
  margin-bottom: 2rem;
}

.card-job__title {
  @include liquid(font-size, 20px, 30px);
  @include liquid(line-height, 28px, 36px);

  font-weight: 600;
}

.card-job__details {
  color: $c-black;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2;
  opacity: 0.4;
}

.card-job__link {
  @include liquid(font-size, 13px, 15px);

  display: flex;
  align-items: center;
  margin-top: 3rem;
  font-weight: 600;
  line-height: 1.5rem;

  span {
    margin-right: 0.4rem;
  }

  &::before {
    @include get-all-space;

    content: '';
  }
}
</style>
