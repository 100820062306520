


































import { defineComponent, ref } from '@vue/composition-api'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

export default defineComponent({
  name: 'duo-ctas',
  components: {
    LineSplitter,
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
  },
  setup() {
    const titleRef = ref()

    const animateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), 'start')
        }
      }
    }

    return {
      animateIn,
      titleRef,
    }
  },
})
