<template>
  <div v-enter-viewport @enterViewport="animateIn">
    <div class="card-testimonial">
      <div class="card-testimonial__picture-container">
        <ZoomOutImg
          :autoplay="false"
          class="card-testimonial__picture"
          :sets="[350, 640]"
          :picture="content.picture"
        />
      </div>
      <div class="card-testimonial__content">
        <svg
          class="card-testimonial__icon"
          v-svg
          size="0 0 24 24"
          symbol="quote"
        ></svg>
        <LineSplitter
          class="card-testimonial__title"
          :content="content.title"
          ref="titleRef"
        />
        <LineSplitter
          class="card-testimonial__htmltext"
          v-if="content.text"
          :content="content.text"
          ref="detailsRef"
        />

        <div class="card-testimonial__name">{{ content.name }}</div>
        <div class="card-testimonial__job">{{ content.job }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import ZoomOutImg from '@/components/animation/ZoomOutImg.vue'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

export default defineComponent({
  name: 'card-testimonial',
  components: {
    ZoomOutImg,
    LineSplitter,
  },
  props: {
    content: {
      type: Object,
    },
  },
  setup() {
    const titleRef = ref()
    const detailsRef = ref()

    const animateIn = () => {
      if (motionOK()) {
        const stagger = 0.1
        const duration = 1
        const offset = duration - stagger

        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), 'start')
        }

        if (detailsRef.value) {
          TL.add(detailsRef.value.getTween(), `-=${offset}`)
        }
      }
    }

    return {
      animateIn,
      titleRef,
      detailsRef,
    }
  },
})
</script>

<style lang="scss" scoped>
.card-testimonial {
  text-align: left;
  background-color: $c-white;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.card-testimonial__picture-container {
  position: relative;
  overflow: hidden;
  aspect-ratio: 440/295;

  ::v-deep {
    .clip {
      aspect-ratio: 1;
    }

    .card-testimonial__picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.card-testimonial__content {
  @include liquid(padding-block, 10px, 20px);
  @include liquid(padding-inline, 20px, 40px);
}

.card-testimonial__icon {
  fill: $c-main;
}

.card-testimonial__title {
  margin-top: 0.4rem;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 3.2rem;
}

.card-testimonial__htmltext {
  @include liquid(font-size, 13px, 15px);

  margin-top: 1rem;
  line-height: 24px;
}

.card-testimonial__name {
  margin-top: 4rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 3rem;
}

.card-testimonial__job {
  @include liquid(line-height, 24px, 30px);

  color: $c-gray-dark;
  font-size: 1.5rem;
}
</style>
