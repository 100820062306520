






























































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import CardJob from '@/components/jobs/CardJob.vue'
import CardTestimonial from '@/components/jobs/CardTestimonial.vue'
import WebP from '@/components/WebP.vue'

export default defineComponent({
  name: 'carousel',
  components: {
    CardJob,
    CardTestimonial,
    WebP,
  },
  props: {
    content: {
      required: true,
      type: Array,
    },
    cardType: {
      type: String,
      default: 'job', // Could be job or testimonial
    },
  },
  setup() {
    let flickity
    const list = ref<null | HTMLElement>(null)
    const activeSlideIndex = ref(0)

    const prev = () => {
      if (flickity) {
        flickity.previous(true, false)
      }
    }

    const next = () => {
      if (flickity) {
        flickity.next(true, false)
      }
    }

    onMounted(async () => {
      const Flickity = await import(
        /* webpackChunkName: 'vendor-flickity' */ 'flickity'
      ).then(module => module.default)

      flickity = new Flickity(list.value as HTMLElement, {
        autoPlay: false,
        prevNextButtons: false,
        pageDots: false,
        draggable: true,
        wrapAround: false,
        selectedAttraction: 0.015,
        friction: 0.25,
        cellAlign: 'left',
      })

      flickity.on('change', () => {
        activeSlideIndex.value = flickity.selectedIndex
      })
    })

    return {
      activeSlideIndex,
      list,
      prev,
      next,
    }
  },
})
